 <template>
    <div id="wrapper" class="bg-light-dark">
      <div v-show="loading" class="container bg-light-dark">
        <div class="center">
          <div class="fingerprint-spinner">
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
            <div class="spinner-ring"></div>
          </div>
        </div>
      </div>  

      <transition name="fade" mode="out-in" v-show="!loading">
        <div class="body">
          <navbar v-model:prop_data=prop_data></navbar>

          <div class="row bg-dark">
            <transition name="fade" mode="out-in">
              <open-nav></open-nav>
            </transition>

            <section :class="['headliner', 'bg-' + headliner.background_color]" v-if="headliner" id="headliner">
              <headliner :page="'Home'" v-model:prop_data=headliner></headliner>
            </section>

            <section id="team" :class="['search', 'bg-light-dark']" style="z-index: 5">
              <div class="container">
                <div class="row ps-4 pe-4">
                  <p class="text-white text-uppercase mb-2 fw-bold" style="letter-spacing: 2.5px;">Who we are</p>
                  <h3 class="text-light-grey" style="letter-spacing: -1px; font-weight: 300;">Learn about our company values</h3>
                </div>

                <div class="row" style="margin-top: 3rem;">
                  <div class="col-md-12 col-sm-12">
                    <h2 class="text-white text-center fw-small mb-4 large-text">
                      <span class="text-secondary-orange fw-bold">Our Mission is</span> to inspire healthier communities by connecting people and businesses to quality health products by leveraging on technology.
                    </h2>

                    <h2 class="text-white text-center fw-small mb-4 large-text">
                      <span class="text-secondary-orange fw-bold">Our Vision is</span> Healthier and happier families in Africa, now and for the future
                    </h2>

                    <h2 class="text-white text-center fw-small mt-4 large-text" style="position: relative; top: 1rem;">
                      <span class="text-secondary-orange fw-bold">Our Values are</span>
                      <div class="bg-secondary-orange rounded" style="padding-top: 5px; width: 6%; margin: auto;"></div>
                    </h2>

                  </div>
                </div>
              </div>

              <div class="container p-4">
                <div class="row" style="margin-top: 3rem;">  
                    <div class="col-md-8 offset-md-2 col-sm-12 order-top d-flex all-values">
                      <div class="values p-2">
                        <img :src="require('../../assets/img/values/partnerships.png')" class="img-fluid image-style">

                        <div class="value">
                          <p class="text-secondary-orange text-uppercase mb-2 fw-bold" style="letter-spacing: 2.5px;">Partnerships</p>
                          <h4 class="text-white mb-3 text">We achieve more when we collaborate and work together.</h4>
                        </div>                        
                      </div>                          
                    </div>
                </div>

                <div class="row" style="margin-top: 3rem;">  
                    <div class="col-md-8 offset-md-2 col-sm-12 order-top d-flex all-values">
                      <div class="values p-2">
                        <img :src="require('../../assets/img/values/agility.png')" class="img-fluid alt-image-style">

                        <div class="value-right">
                          <p class="text-secondary-orange text-uppercase mb-2 fw-bold" style="letter-spacing: 2.5px;">Agility</p>
                          <h4 class="text-white mb-3 text">We challenge the status quo with open minds, focus and speed.</h4>
                        </div>

                        <img :src="require('../../assets/img/values/agility.png')" class="img-fluid image-style hide">
                      </div>                          
                    </div>
                </div>

                <div class="row" style="margin-top: 3rem;">  
                    <div class="col-md-8 offset-md-2 col-sm-12 order-top d-flex all-values">
                      <div class="values p-2">
                        <img :src="require('../../assets/img/values/diversity.png')" class="img-fluid image-style" style="border-radius: 50%; width: 206px; height: 208px;">

                        <div class="value">
                          <p class="text-secondary-orange text-uppercase mb-2 fw-bold" style="letter-spacing: 2.5px;">Diversity</p>
                          <h4 class="text-white mb-3 text">We embrace people from different backgrounds, cultures, gender, orientation and thinking styles</h4>
                        </div>
                      </div>                          
                    </div>
                </div>

                <div class="row" style="margin-top: 3rem;">  
                    <div class="col-md-8 offset-md-2 col-sm-12 order-top d-flex all-values">
                      <div class="values p-2">
                        <img :src="require('../../assets/img/values/mutual-respect.png')" class="img-fluid alt-image-style">
                        <div class="value-right">
                          <p class="text-secondary-orange text-uppercase mb-2 fw-bold" style="letter-spacing: 2.5px;">Mutual Respect</p>
                          <h4 class="text-white mb-3 text">We value everyone and treat people with dignity and professionalism.</h4>
                        </div>

                        <img :src="require('../../assets/img/values/mutual-respect.png')" class="img-fluid image-style hide">
                      </div>                          
                    </div>
                </div>                

                <div class="row" style="margin-top: 3rem;">  
                    <div class="col-md-8 offset-md-2 col-sm-12 order-top d-flex all-values">
                      <div class="values p-2">
                        <img :src="require('../../assets/img/values/commitment.png')" class="img-fluid image-style" style="border-radius: 50%; width: 200px; height: 200px;">

                        <div class="value">
                          <p class="text-secondary-orange text-uppercase mb-2 fw-bold" style="letter-spacing: 2.5px;">Commitment</p>
                          <h4 class="text-white mb-3 text">Superior quality products and services.</h4>
                        </div>                        
                      </div>                          
                    </div>
                </div>                
              </div>
            </section>
            
            <section id="problem" :class="['search', 'bg-dark']">
              <products></products>
            </section>
            
            <svg class="bg-dark" v-if="services" xmlns="http://www.w3.org/2000/svg" viewBox="0 100 1440 200">
              <path fill="#373a3e" fill-opacity="1" d="M0,128L80,128C160,128,320,128,480,138.7C640,149,800,171,960,197.3C1120,224,1280,256,1360,272L1440,288L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
            </svg>
                          
            <section v-if="services" id="services" :class="['work', 'bg-' + services.background_color, 'pt-0', 'pb-0']">
              <div class="container">

                <div class="row ps-4 pe-4">
                  <p class="text-white text-uppercase mb-2 fw-bold" style="letter-spacing: 2.5px;">{{ services.title }}</p>
                  <h3 class="text-light-grey" style="letter-spacing: -1px; font-weight: 300;">{{ services.tagline }}</h3>
                </div>

                <div class="row ps-4 pe-4" style="margin-top: 3rem;">
                  <div class="col-md-6 col-sm-12 mt-4" style="text-align: center;">
                    <font-awesome-icon class="bg-secondary-orange text-white circle mb-4 bg-gradient" style="font-size: 10rem" :icon="['fas', 'globe-africa']"></font-awesome-icon>

                    <p class="text-secondary-orange text-uppercase mb-2 fw-bold" style="letter-spacing: 2.5px; font-size: 1.1rem;">Access</p>
                    <h4 class="text-light-grey fw-small mb-4" style="font-weight: 300; padding: 0rem 3rem">Digitally fulfilling medicines and equipment to consumers and hospitals.</h4>
                  </div>

                  <div class="col-md-6 col-sm-12 mt-4" style="text-align: center;">
                    <font-awesome-icon class="bg-burgundy text-white circle mb-4" style="font-size: 10rem" :icon="['fas', 'piggy-bank']"></font-awesome-icon>

                    <p class="text-secondary-orange text-uppercase mb-2 fw-bold" style="letter-spacing: 2.5px; font-size: 1.1rem;">Savings</p>
                    <h4 class="text-light-grey fw-small mb-4" style="font-weight: 300; padding: 0rem 3rem">Families can save money on medicine and equipment</h4>
                  </div>

                  <div class="col-md-6 col-sm-12 mt-4" style="text-align: center;">
                    <font-awesome-icon class="bg-primary text-white circle mb-4 bg-gradient" style="font-size: 10rem" :icon="['fas', 'boxes']"></font-awesome-icon>
                    <p class="text-secondary-orange text-uppercase mb-2 fw-bold" style="letter-spacing: 2.5px; font-size: 1.1rem;">Quality</p>
                    <h4 class="text-light-grey fw-small mb-4" style="font-weight: 300; padding: 0rem 3rem">Providing quality products and high measuring standards</h4>
                  </div>

                  <div class="col-md-6 col-sm-12 mt-4" style="text-align: center;">
                    <!-- <font-awesome-icon class="bg-success text-white circle mb-4 bg-gradient" style="font-size: 10rem" :icon="['fas', 'leaf']"></font-awesome-icon> -->

                    <img class="bg-white img-fluid text-white circle mb-4 bg-gradient" style="font-size: 10rem" :src="require('../../assets/img/hand-holding-seedling.png')" alt="">
                    <p class="text-secondary-orange text-uppercase mb-2 fw-bold" style="letter-spacing: 2.5px; font-size: 1.1rem;">Sustainability</p>
                    <h4 class="text-light-grey fw-small mb-4" style="font-weight: 300; padding: 0rem 3rem">Proper Repair, Recycle and Reuse to safeguard the planet</h4>
                  </div>
                </div>
              </div> 
            </section>
            
            <svg class="bg-dark" v-if="services" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250">
              <path fill="#373a3e" fill-opacity="1" d="M0,32L120,48C240,64,480,96,720,112C960,128,1200,128,1320,128L1440,128L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path>
            </svg>

            <section v-if="partners" id="partners" :class="['partners', 'about', 'bg-' + partners.background_color, 'pt-0']">        
              <partners :page="'Home'" v-model:prop_data=partners></partners>
            </section>  

            <!-- <section v-if="testimonials" id="testimonials" :class="['testimonials', 'about', 'bg-' + testimonials.background_color, 'text-'  + testimonials.color ]">        
              <testimonials :page="'Home'" v-model:prop_data=testimonials></testimonials>
            </section> -->

            <section id="contacts" class="contact bg-light-dark">
              <contacts></contacts>
            </section>
          </div>

          <main-footer v-if="footer" :page="'Home'" v-model:prop_data="footer"></main-footer>
        </div>
      </transition>
    </div>
</template>

<style lang="scss" scoped>
  @import '../../assets/scss/abstracts/_variables';
  @import '../../assets/scss/layout/_spinner';
  
    body {
      background-color: $dark-color!important;
    }

    #wrapper {
      overflow-y: scroll;
      scroll-behavior: smooth;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */      
    }    
        /* Hide scrollbar for Chrome, Safari and Opera */
    #wrapper::-webkit-scrollbar {
      display: none;
    }

    .body {
      padding-left: 0!important;
    }

    .navbar-border-bottom {
      border-bottom: 1px solid #fafafa !important;
      box-shadow: 0 4px 8px rgb(0 0 0 / 3%);
    }

    .about, .work, .search, .contact, .testimonials, .partners, .values {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    .testimonials {
      @include media('<=LGdesktop' ,'>tablet') {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 5px;
      }
    }

    .form-select, .form-control {
      line-height: 3;
      border: 1px solid #eee;
    }

    .form-select option{
      color: $dark-color;
    }

    .form-select option:first-child{
      color: $secondary-secondary-color;
    }

    .card {
      border: 1px solid #eee;
    }
    
    @media (min-width: 576px) {
      .modal-dialog {
        max-width: 800px;
        margin: 1.75rem auto;
      }
    }

    .center {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .circle {
      border-radius: 50%;
      padding: 2.5rem;
      width: 9rem;
      height: 9rem;
    }

    .large-text {
      letter-spacing: -1px;
      font-weight: 300;
      line-height: 45px;
      padding-left: 3rem;
      padding-right: 3rem;

      @include media("<=tablet", ">Lphone") {
        padding-left: 0rem;
        padding-right: 0rem;
      }

      @include media("<=Lphone") {
        padding-left: 0rem;
        padding-right: 0rem;
      } 
    }

    .all-values {
      .values {
        margin: auto;
        display: flex;
        z-index: 3;

        @include media("<=Lphone") {
          margin-left: 0;
          width: 100%;
          display: block;
        } 
      }

      @include media("<=tablet") {
        margin-left: 0;
        width: 100%;
      }       
    }

    .value {
      margin-left: 5rem; 
      margin-top: 3rem;

      @include media("<=tablet") {
        margin-left: 3rem;
        margin-top: 2rem;
      }

      @include media("<=Lphone") {
        margin-left: 0rem;
        margin-top: 1rem;
      }
      
      .text {
        font-weight: 300;
        font-size: 2rem;
        font-weight: 300;

        @include media("<=tablet") {
          font-size: 1.6rem;
        } 
      }
    }

    .value-right {
      margin-right: 5rem; 
      margin-top: 3rem;
      
      .text {
        font-weight: 300;
        font-size: 2rem;
        font-weight: 300;

        @include media("<=tablet") {
          font-size: 1.6rem;
        } 
      }
    }    

    .image-style {
      border-radius: 50%; 
      width: 200px; 
      height: 200px;

      @include media("<=tablet") {
        width: 150px; 
        height: 150px;
      }
    }

    .image-style.hide {
      @include media("<=Lphone") {
        display: none;
      }      
    }

    .alt-image-style {
      display: none;
      border-radius: 50%; 
      width: 200px; 
      height: 200px;

      @include media("<=Lphone") {
        display: block;
        width: 150px; 
        height: 150px;
      }
    }    
</style>

<script>
  import Navbar from '../../components/Custom/HomeNavbar'
  import Headliner from '../../components/Custom/Site/Headliner'
  // import Services from '../../components/Custom/Site/Services'
  import Partners from '../../components/Custom/Site/Partners'
  import Products from '../../components/Custom/Site/Products'
  import Contacts from '../../components/Custom/Site/Contacts'
  import MainFooter from '../../components/Footer'
  import OpenNav from '../../components/OpenNav'

  export default {
    name: 'Home',
    components: {
      Navbar,
      Headliner,
      // Services,
      Partners,
      Products,
      Contacts,
      MainFooter,
      OpenNav
    }, 
    data() {
      return {
        title: {status: true, title: 'Your Data', actions: true}, 
        headliner: {
          "backdrop": "1",
          // "tagline": "Gear Health System | Driving Quality Healthcare",
          "title": "Disrupting Access Barriers to Quality Healthcare in Africa.",
          "logo": "img/logo--detailed.png",
          "image": "img/intro.jpeg",
          "background_color": "light-dark",
          "position": "center ",
          "call_to_action": {
              "text": "Contact Us",
              "link": "#contacts",
              "background_color": "secondary-orange",
              "color": "dark"
          }
        },
        navbar: {
          "background-color": "transparent" 
        },
        values: {
          "title": "What we Do",
          "tagline": "We've built services that help us achieve our goals.",
          "background_color": "light-dark",          
        },
        services: {
          "title": "What we Do",
          "tagline": "We've built services that help us achieve our goals.",
          "background_color": "light-dark",
          "service-items": [
            {
              "title": "Access",
              "tagline": "Better Access to Healthcare",
              "description": "We have established time-tested and refined marketing strategies that will help you sell your property fast. Find the most qualified buyer for your property",
              "align": "right"
            },
            {
              "title": "Support",
              "tagline": "Keeping customers satisfied",
              "description": "We are here to help you every step of the way. The people on our team are always friendly, totally committed to your success. We can handle any questions or requests you have.",
              "align": "left"
            },
            {
              "title": "Negotiation",
              "tagline": "We negotiate for you",
              "description": "Finding properties that meet the needs of our buyers is our top priority. Our process helps you successfully handle offer and negotiation processes.",
              "align": "right"
            }
          ]
        },
        partners: {
          "title": "Our Partners",
          "tagline": "We work with the best to build the best",
          "background_color": "dark",
          "partner-items": [
            {
              "title": "DeveX",
              "link": "https://pages.devex.com/prescription-for-progress-2022.html",
              "image": "partners/devex.png"
            },           
          ],
        },
        testimonials: {
          "title": "All Testimonials",
          "tagline": "See what people are saying about us!",
          "color": "white",
          "background_color": "light-dark",
          "testimonial-items": [
            {
              "text": "No words to express my huge gratitude you to you and to the team for the advice you provided. Excellent work!",
              "author": "David Mukabi"
            },
            {
              "text": "We've seen amazing results already.",
              "author": "Hyacinthe V."
            },
            {
              "text": "Thank You! I love the help I was given!",
              "author": "Marybelle"
            },
            {
              "text": "It's really wonderful.",
              "author": "Morris Q."
            }
          ],
        },
        footer: {
          "title": "About Gear Health Systems.",
          "address": "",
          "phone_number": "+254 (0)713 152 100",
          "email": "info@gearhealthsystem.com",
          "background_color": "dark",
          "description": "Gear Health Systems is Disrupting Access Barriers to Quality Healthcare in Africa",
          "call_to_action": {
            "text": "Contact us",
            "background_color": "secondary-orange",
            "color": "white",
            "link": "#contacts"
          },
          navigation: [
            {
              "text": "Team",
              "link": "#team",
              "type": "id"
            },
            {
              "text": "The Problem",
              "link": "#problem",
              "type": "id"
            },
            {
              "text": "Services",
              "link": "#services",
              "type": "id"
            },
            {
              "text": "Partners",
              "link": "#partners",
              "type": "id"
            },
            {
              "text": "Contacts",
              "link": "#contacts",
              "type": "id"
            },
            {
              "text": "Terms of Service",
              "link": "tos",
              "type": "route"
            }   ,         
            {
              "text": "Privacy Policy",
              "link": "privacy",
              "type": "route"
            }   
          ],
          socials: [
            {
              "social": "twitter",
              "link": "https://www.twitter.com/@GearHealthSys",
              "background_color": "info"
            },
            {
              "social": "linkedin",
              "link": "https://www.linkedin.com/company/gear-health-systems",
              "background_color": "primary"
            },
            {
              "social": "instagram",
              "link": "http://www.instagram.com/gearhealthsys",
              "background_color": "burgundy"
            },
            {
              "social": "youtube",
              "link": "https://www.youtube.com/channel/UCQjUg2zZ-jx1KfQMcDkmr7w",
              "background_color": "danger"
            },
            {
              "social": "facebook",
              "link": "https://www.facebook.com/Gear-Health-Systems-102109359062443",
              "background_color": "primary"
            }                                     
          ],          
        },
        loading: true
      } 
    },  
    mounted() {
      setTimeout(() => {
        this.loading = false
      }, 2000);

      // this.onScrollReact()
    },
    methods: {
      onScrollReact() {
        var observer = new IntersectionObserver(function(entries) {
          if(entries[0].isIntersecting === true)
            alert('Element is fully visible in screen');
        }, { threshold: [0.5] });

        observer.observe(document.querySelector("#services"));
      }
    }
  }
</script>
