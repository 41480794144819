<template>
  <nav class="navbar fixed-top navbar-dark bg-dark" style="z-index: 7">
    <div class="container">

      <div class="navbar-brand p-0 ps-4">
        <span style="display: -webkit-inline-box;">
          <img :src="require('../../assets/img/new/ghs-01.png')" alt="" class="img-fluid" style="width: 54px">
        </span>
      </div>

      <span class="navbar-start nav-menu-bar ms-auto">
        <div class="navbar-brand">
          <span style="display: -webkit-inline-box;">
            <a class="text-decoration-none link" @click="openNav()" style="cursor: pointer">
              <font-awesome-icon :icon="['fas', 'bars']"></font-awesome-icon>
            </a>
          </span>
        </div>
      </span>

      <span class="navbar-left nav-options">
        <div class="navbar-brand">
          <span style="display: -webkit-inline-box;">
            <a class="text-decoration-none link text-white" @click="changeSection('team')">
              <span :class="['text-uppercase']">Team</span>
            </a>
          </span>
        </div>  

        <div class="navbar-brand">
          <span style="display: -webkit-inline-box;">
            <a class="text-decoration-none link text-white" @click="changeSection('problem')">
              <span :class="['text-uppercase']">The Problem</span>
            </a>
          </span>
        </div>  

        <div class="navbar-brand">
          <span style="display: -webkit-inline-box;">
            <a class="text-decoration-none link text-white" @click="changeSection('services')">
              <span :class="['text-uppercase']">Services</span>
            </a>
          </span>
        </div>  

        <div class="navbar-brand">
          <span style="display: -webkit-inline-box;">
            <a class="text-decoration-none link text-white" @click="changeSection('partners')">
              <span :class="['text-uppercase']">Partners</span>
            </a>
          </span>
        </div>  

        <div class="navbar-brand">
          <span style="display: -webkit-inline-box;">
            <a href="#contacts" class="text-decoration-none link text-white" @click="changeSection('contacts')">
              <span :class="['text-uppercase']">Contacts</span>
            </a>
          </span>
        </div>           
      </span>      

      <profile></profile>

      <div class="divider mt-4 d-none"></div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
  @import '../../assets/scss/layout/_navbar.scss';
  @import '../../assets/scss/helpers/_breakpoints.scss';
  @import '../../assets/scss/abstracts/_colors.scss';
  @import '../../assets/scss/abstracts/_variables.scss';

  .link {
    cursor: pointer;
    font-size: 13px;
    letter-spacing: 1px;

    &:hover {
      font-weight: 600;
      color: $orange-secondary-color!important;
      text-decoration: underline!important;
      text-underline-offset: 15px;
      text-decoration-thickness: 2px!important;
    }
  }
  
  .nav-menu-bar {
    display: none;

    @include media("<=tablet") {
      display: block;
    }
    
    a {
      color: $dark-color;

      @include media("<=tablet") {
        color: #fff;
      }
    }
  }

  .nav-options {
    @include media("<=tablet") {
      display: none!important;
    }
  }

</style>

<script>
import Profile from '../../components/Profile';

export default {
    name: 'Navbar',
    props: {
      prop_data: {
        required: true,
        type: Object
      }
    },
    components: {
      Profile
    },
    data() {
      return {
        color: 'secondary-orange',
        section: 'headliner'    
      }
    },
    methods: {
      toggled() { 
        document.querySelector('#sidebar-wrapper').classList.add('toggled');
        document.querySelector('.backdrop').classList.add('on');
      },
      changeSection(value) {
        this.section = value
        document.getElementById(value).scrollIntoView(true);
      },
      openNav() {
        document.getElementById('open-nav').classList.remove('d-none');
      }
    }
}
</script>