<template>
    <div class="container">
        <div class="row ps-4 pe-4">
            <div class="col-12">
                <p class="text-white text-uppercase mb-2 fw-bold" style="letter-spacing: 2.5px;">The Problem.</p>
                <h3 style="
                    letter-spacing: -1px;
                    font-weight: 200;">These are the problems we're aiming to overcome.</h3>
                
                <ul class="list-group list-group-horizontal text-no wrap overflow-auto p-0 border-bottom-0 product-menu">
                    <li class="list-group-item item pt-4 pb-0 border-0 border-end-0 bg-light-dark" @click="changeScreen(0)">
                        <h6 v-if="screen == 0" class="text-secondary-orange text-center" style="font-size: 1.2rem">Access to Healthcare</h6>
                        <h6 v-if="screen != 0" class="text-white text-center" style="font-size: 1.2rem">Access to Healthcare</h6>
                        <div v-if="screen == 0" class="bg-secondary-orange mx-auto mt-4 mb-0 product-item-active rounded"></div>
                    </li>

                    <li class="list-group-item item pt-4 pb-0 border-0 border-end-0 bg-light-dark" @click="changeScreen(1)">
                        <h6 v-if="screen == 1" class="text-secondary-orange text-center" style="font-size: 1.2rem">Cost of Healthcare</h6>
                        <h6 v-if="screen != 1" class="text-white text-center" style="font-size: 1.2rem">Cost of Healthcare</h6>
                        <div v-if="screen == 1" class="bg-secondary-orange mx-auto mt-4 mb-0 product-item-active rounded"></div>
                    </li>

                    <li class="list-group-item item pt-4 pb-0 border-0 border-end-0 bg-light-dark" @click="changeScreen(2)">
                        <h6 v-if="screen == 2" class="text-secondary-orange text-center" style="font-size: 1.2rem">Poor Quality Products</h6>
                        <h6 v-if="screen != 2" class="text-white text-center" style="font-size: 1.2rem">Poor Quality Products</h6>
                        <div v-if="screen == 2" class="bg-secondary-orange mx-auto mt-4 mb-0 product-item-active rounded"></div>
                    </li>

                    <li class="list-group-item item pt-4 pb-0 border-0 border-end-0 bg-light-dark" @click="changeScreen(3)">
                        <h6 v-if="screen == 3" class="text-secondary-orange text-center" style="font-size: 1.2rem">Environment Pollution</h6>
                        <h6 v-if="screen != 3" class="text-white text-center" style="font-size: 1.2rem">Environment Pollution</h6>
                        <div v-if="screen == 3" class="bg-secondary-orange mx-auto mt-4 mb-0 product-item-active rounded"></div>
                    </li>                    
                </ul>

                <div v-show="screen == 0" id="section-0" class="row content">
                    <p class="text-secondary-orange text-uppercase mb-2 fw-bold mb-0 mt-4 small-title d-none" style="letter-spacing: 2.5px;">Poor Access to Healthcare</p>                                      
                    <div class="col-md-8 product-content">
                        <h2 class="text-white fw-small mb-4" style="letter-spacing: -1px; font-weight: 300; line-height: 45px">&bull; There are a lot of financial barriers for people within African Countries in accessing quality healthcare, like exclusions from insurance.</h2>

                        <h2 class="text-white fw-small mb-4" style="letter-spacing: -1px; font-weight: 300; line-height: 45px">&bull; Misinformation and disinformation has resulted in poor uptake of lifesaving healthcare services.</h2>


                        <div class="d-flex navigation mt-4 d-none">
                            <button class="btn btn-info ms-auto w-100 me-2 mb-2" @click="changeScreen(1, 'section-1')">
                                <font-awesome-icon class="me-2" :icon="['fas', 'angle-right']"></font-awesome-icon> High Cost of Healthcare
                            </button>  
                        </div>
                    </div>
                </div> 

                <div v-show="screen == 1" id="section-1" class="row content">
                    <p class="text-secondary-orange text-uppercase mb-2 fw-bold mb-0 mt-4 small-title d-none" style="letter-spacing: 2.5px;">High Cost of Healthcare</p>                  
                    <div class="col-md-8 product-content">
                        <h2 class="text-white fw-small mb-4" style="letter-spacing: -1px; font-weight: 300; line-height: 45px">&bull; Non-communicable Diseases (NCD's) are responsible for a lot of hospital admissions and deaths, but only a fraction of patients have access to life-saving medicine.</h2>


                        <div class="d-flex mt-4 navigation d-none">
                            <button class="btn btn-secondary ms-auto w-100 me-2 mb-2" @click="changeScreen(0, 'section-0')">
                                <font-awesome-icon class="me-2" :icon="['fas', 'angle-left']"></font-awesome-icon> Access to Healthcare
                            </button>   

                            <button class="btn btn-secondary ms-auto w-100 me-2 mb-2" @click="changeScreen(2, 'section-2')">
                                <font-awesome-icon class="me-2" :icon="['fas', 'angle-right']"></font-awesome-icon> Poor Quality Products
                            </button>   
                        </div>                                            
                    </div>
                </div>  

                <div v-show="screen == 2" id="section-2" class="row content">
                    <p class="text-secondary-orange text-uppercase mb-2 fw-bold mb-0 mt-4 small-title d-none" style="letter-spacing: 2.5px;">Poor Quality Products</p>                                      
                    <div class="col-md-8 product-content">
                        <h2 class="text-white fw-small mb-4" style="letter-spacing: -1px; font-weight: 300;">&bull; A lot of medicine sold in the African market are counterfeit drugs, which poses a risk to patients in need of the medicine.</h2>

                        <h2 class="text-white fw-small mb-4" style="letter-spacing: -1px; font-weight: 300;">&bull; It also wastes money that could be redirected to better medicine or insurance covers.</h2>

                        <div class="d-flex navigation mt-4 d-none">
                            <button class="btn btn-info ms-auto w-100 me-2 mb-2" @click="changeScreen(1, 'section-1')">
                                <font-awesome-icon class="me-2" :icon="['fas', 'angle-left']"></font-awesome-icon> High Cost of Healthcare
                            </button> 

                            <button class="btn btn-info ms-auto w-100 me-2 mb-2" @click="changeScreen(3, 'section-3')">
                                <font-awesome-icon class="me-2" :icon="['fas', 'angle-left']"></font-awesome-icon> Environmental Pollution
                            </button>  
                        </div>
                    </div>
                </div> 

                <div v-show="screen == 3" id="section-3" class="row content">
                    <p class="text-secondary-orange text-uppercase mb-2 fw-bold mb-0 mt-4 small-title d-none" style="letter-spacing: 2.5px;">Environmental pollution</p>                  
                    <div class="col-md-8 product-content">
                        <h2 class="text-white fw-small mb-4" style="letter-spacing: -1px; font-weight: 300;">&bull; There is a lot of improper waste in disposing medical waste in hospitals and in households, which causes environmental waste</h2>

                        <h2 class="text-white fw-small mb-4" style="letter-spacing: -1px; font-weight: 300;">&bull; A lack of proper means of disposing drugs can also lead to these drugs resurfacing in the market.</h2>

                        <div class="d-flex mt-4 navigation d-none">
                            <button class="btn btn-secondary ms-auto w-100 me-2 mb-2" @click="changeScreen(2, 'section-2')">
                                <font-awesome-icon class="me-2" :icon="['fas', 'angle-left']"></font-awesome-icon> Access to Healthcare
                            </button>   

                            <button class="btn btn-secondary ms-auto w-100 me-2 mb-2" @click="changeScreen(0, 'section-0')">
                                <font-awesome-icon class="me-2" :icon="['fas', 'angle-right']"></font-awesome-icon> Poor Access to Healthcare
                            </button>   
                        </div>                                            
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../../assets/scss/helpers/_breakpoints.scss';
    @import '../../../assets/scss/abstracts/_variables.scss';
    
    .bg-light-dark {
        background: #373a3e!important;
    }

    .content {
        margin-top: 3rem;

        @include media("<=Lphone") {
            max-width: 100%!important;
        } 

        .small-title {
            @include media("<=Lphone") {
                display: block!important;
            }               
        }
    }

    .product-content {
        margin-left: 8%;


        .navigation {
            @include media("<=Lphone") {
                display: block!important;
            }             
        }         

        @include media("<=tablet", ">Lphone") {
            margin-left: 4%;
        }

        @include media("<=Lphone") {
            margin-left: 0;
        }        
    }

    .product-menu {
        margin-top: 3rem;

        .item {
            // width: 33.33%;
            width: 50%;
            cursor: pointer; 
        }

        @include media("<=Lphone") {
            // padding-bottom: 5px;
            display: none;
        }        
    }    

    .product-item-active {
        width: 30%; 
        height: 2px;

        @include media("<=tablet") {
            width: 70%;
        }   
    }

</style>

<script>
    export default {
        name: 'Products',
        data() {
            return {
                screen: 0
            }
        },
        methods: {
            changeScreen(value, id) {
                this.screen = value
                setTimeout(() => {
                    document.getElementById(id).scrollIntoView({behaviour: "smooth"}); 
                }, 300);
            }
        }
    }
</script>